import React from "react"

import Layout from "@src/components/Layout"
import Row from "../../V2/components/Row/Row"
import ImageRow from "../../V2/Features/components/ImageRow/ImageRow"
import Button from "../../../components/Button/Button"
import getAppUrl from "@src/utils/getAppUrl"
import DotBG from "../../V2/components/DotBG/DotBG"
import CalloutWithDemoForm from "../../../components/CalloutWithDemoForm/CalloutWithDemoForm"
import Column from "../../V2/components/Column/Column"
import Splash from "../../V2/Features/components/Splash"
import { useBreakpoint } from "gatsby-plugin-breakpoints"

import HeaderImage from "@src/icons/industries/insurance_header.png"
import SectionOne from "@src/icons/industries/insurance_section_one.png"
import SectionTwo from "@src/icons/industries/insurance_section_two.png"
import SectionThree from "@src/icons/industries/insurance_section_three.png"
import SectionFour from "@src/icons/industries/insurance_section_four.png"
import SectionFive from "@src/icons/industries/insurance_section_five.png"
import CardOne from "@src/icons/industries/insurance_card_one.png"
import CardTwo from "@src/icons/industries/insurance_card_two.png"
import CardThree from "@src/icons/industries/insurance_card_three.png"
import OG from "@src/icons/og/OG_Insurance.jpg"
import * as styles from "./Insurance.module.scss"

const Insurance = () => {
  const appUrl = getAppUrl.hook()
  const breakpoints = useBreakpoint()

  return (
    <Layout
      title="No-code Solutions Tailored for Insurance Businesses"
      description="Streamline Operations, Enhance Experience, and Business Success."
      url="https://canonic.dev/industries/insurance-businesses"
      ogImage={OG}
      className={styles.retail}
    >
      <Splash
        title="No-code Solutions Tailored for Insurance Businesses"
        subtitle="Streamline Operations, Enhance Experience, and Business Success."
        illustration={<img src={HeaderImage} />}
        ctas={[
          <a href={appUrl} target="_blank" rel="noopener noreferrer">
            <Button isPrimary>Try for free</Button>
          </a>,
          <a href={"#get-a-demo"}>
            <Button borderless marginLeft className={styles.retailCtaOutline}>
              Get a Demo
            </Button>
          </a>,
        ]}
        reverseOnMobile
      />
      <Row>
        <div className={styles.retailSolution}>
          <h2>Dodge the Struggle with No-code</h2>
          <p>
            Are you struggling to find software solutions that meet your unique
            needs as an insurance business?
          </p>
          <p>
            You don’t need to bogged down in complex software development
            projects that drain your time and resources to have a seamless
            Insurance Business.
          </p>
          <p>
            Canonic enables you to build custom web applications quickly and
            easily, without any coding skills. With its No-code development, you
            can create the web applications you need to streamline your
            operations, improve your customer and agent experiences, and
            transform your insurance business.
          </p>
        </div>
      </Row>
      <section>
        <Row className={styles.retailProcess}>
          <h2>Custom Solution to Insurance Businesses</h2>
          <ImageRow
            title="Claims Management"
            subtitle=" Automate claims processing, reduce claim-handling time, and improve the customer experience by building an online portal for customers to file & track claims."
            screenshot={SectionOne}
            borderless
            className={styles.retailProcessSources}
          />
          <ImageRow
            title="Policy Management"
            subtitle="Create a policy management system to track policy information, automate the underwriting process, etc.. Increase productivity and streamline operations by automating repetitive tasks."
            screenshot={SectionTwo}
            reverse
            borderless
            className={styles.retailProcessBoost}
          />
          <ImageRow
            title="Customer Portals"
            subtitle="Create a personalized platform for customers to access policy information, view coverage, etc. Enhance the customer experience by providing 24/7 access to policy information."
            screenshot={SectionThree}
            borderless
            className={styles.retailTailorMade}
          />
          <ImageRow
            title="Agent Portals"
            subtitle="Build a platform to manage your agent network, track sales, and share marketing materials. Simplify agent management, streamline operations, and improve communication with agents."
            screenshot={SectionFour}
            reverse
            borderless
            className={styles.retailProcessBoost}
          />
          <ImageRow
            title="Compliance Tools"
            subtitle="Develop a tool to manage compliance requirements, track regulatory changes, and monitor compliance performance. Ensure compliance and reduce risk."
            screenshot={SectionFive}
            borderless
            className={styles.retailTailorMade}
          />
        </Row>
      </section>

      <section>
        <Row>
          <h1 className={styles.retailCardsTitle}>
            Get the edge for Insurance Businesses
          </h1>
          <div className={styles.retailCards}>
            <Column.Wrap>
              <Column
                title="Increased Agility"
                icon={CardOne}
                description={
                  "Stay ahead of the curve by quickly creating and modifying web applications to meet changing customer and market needs."
                }
              />
              <Column
                title="Improved efficiency"
                icon={CardTwo}
                description={
                  "Streamline insurance operations with automate claims processing, real-time policy updates, and compliance monitoring."
                }
              />
              <Column
                title="Competitive advantage"
                icon={CardThree}
                description={
                  "Gain a competitive edge with custom web applications to enhance user experience and meet specific business needs, helping you stand out in a crowded market."
                }
              />
            </Column.Wrap>
          </div>
        </Row>
      </section>

      <CalloutWithDemoForm
        originPage="Canonic for Insurance"
        id="get-a-demo"
        // className={styles.retailCallout}
      />
    </Layout>
  )
}

export default Insurance
